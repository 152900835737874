import React, { useState } from 'react';
import ComparatifAccordeonDesktop from './ComparatifAccordeonDektop';
import Question from './Question';

export default function Faq() {
  // État pour suivre la section actuellement ouverte
  const [openSectionIndex, setOpenSectionIndex] = useState(null);

  // Fonction pour changer la section ouverte (ou refermer si on reclique)
  const handleSectionToggle = (index) => {
    if (openSectionIndex === index) {
      setOpenSectionIndex(null);
    } else {
      setOpenSectionIndex(index);
    }
  };

  return (
    <div className='flex flex-col justify-center items-center mt-12 lg:mx-auto'>
      <h2 className='text-[1.875rem] text-[#E51337] font-Poppins font-bold lg:w-[36rem] lg:h-[2.25rem] mt-8'>
        Frequently Asked Questions [FAQ]
      </h2>
      <p className='font-DMSans text-[#78716C] font-normal mx-6 mt-4'>
        Les questions les plus fréquentes
      </p>

      <div className='w-full lg:w-[90%] grid grid-cols-1 mt-8'>

        {/* SECTION 0 : INFORMATIONS GÉNÉRALES */}
        <ComparatifAccordeonDesktop
          title="Informations générales"
          open={openSectionIndex === 0}
          onToggle={() => handleSectionToggle(0)}
        >
          <Question
            q="Comment ouvrir un compte de monnaie électronique"
            rep="Un compte de monnaie électronique B Partner est ouvert en ligne. Suivez le lien https://app.b-partner.com pour vous enregistrer. Pour les comptes particuliers l’ouverture se fait entièrement en ligne. Pour les comptes professionnels l’ouverture se fait en ligne, mais la validation du compte est réalisé par nos équipes B Partner."
          />

          {/* La question sur les types de comptes est commentée dans l'ancien fichier */}

          <Question
            q="Comment puis-je accéder à mes comptes en ligne ?"
            rep="Pour accéder à votre compte en ligne rien de plus simple ! Vous pourrez y accéder depuis votre ordinateur ou téléphone portable via le lien https://app.b-partner.com. Une fois votre identifiant et mot de passe renseigné vous pourrez accéder à votre compte B Partner."
          />

          <Question
            q="Quels services proposez-vous ?"
            rep=""
          />

          <Question
            q="Quels documents sont nécessaires pour ouvrir un compte B Partner ?"
            rep=""
          />

          <Question
            q="Quels documents sont nécessaires pour ouvrir un compte de monnaie électronique ? (particulier)"
            rep="Conditions et documents requis pour l’ouverture d’un compte Particulier B Partner  
Conditions d’accès :  
· Accessible aux personnes physiques.  
· Résidents fiscaux en France ou en Belgique.  
· Majeurs au moment de l’entrée en relation.  
· Possédant une pièce d’identité valide (non expirée) : carte d’identité nationale, passeport, permis de conduire.  
· Ne pas être 'américain' au sens FATCA.  
Justificatifs à fournir :  
· Une pièce d’identité en cours de validité : carte d’identité nationale, passeport, permis de conduire.  
· Un justificatif de domicile ou de revenu peut être demandé dans certains cas."
          />

          <Question
            q="Quels documents sont nécessaires pour ouvrir un compte de monnaie électronique ? (professionnel)"
            rep="Conditions et documents requis pour l’ouverture d’un compte Professionnel B Partner  
Conditions d’accès :  
· Accessible aux personnes morales dont le siège est en France ou en Belgique.  
· Le représentant légal doit être une personne physique résidant en France ou en Belgique et majeure au moment de l’entrée en relation.  
· Le représentant légal peut être un bénéficiaire effectif ou une personne physique ayant un mandat de gestion contractualisé avec l’entreprise.  
· Le représentant légal et tous les bénéficiaires effectifs ne doivent pas être 'américains' au sens FATCA.  
Justificatifs à fournir :  
· Pour l’entreprise : un Kbis ou un enregistrement au registre du commerce, ainsi que des statuts à jour.  
· Pour les bénéficiaires effectifs et représentants légaux : une pièce d’identité en cours de validité (carte d’identité nationale, passeport, permis de conduire). Dans certains cas, un justificatif de domicile ou de revenu."
          />

          <Question
            q="Comment puis-je trouver la succursale ou le distributeur automatique de billets le plus proche ?"
            rep="B Partner est une fintech spécialisé dans le gestion innovante de vos finances. Nos bureaux sont situés à Paris, et nos clients peuvent nous rencontrer sur rendez-vous. Concernant les distributeurs automatiques de billets (DAB), B Partner ne dispose pas de ce réseau. Nos cartes B Partner sont néanmoins utilisables dans tous les DABs en France et dans le monde disposant du petit logo « VISA »."
          />

          <Question
            q="Proposez-vous des services en ligne et mobiles ?"
            rep="B Partner propose une application accessible via web, et bientôt sur Apple Store et Play Store. Elle permet d'ouvrir un compte, gérer des cartes de paiement, effectuer des paiements SEPA et en devises, ajuster les paramètres de la carte en temps réel, et bénéficier du programme de fidélité B Partner Club. B Partner met aussi à disposition une solution de paiement dans plus de 50 devises grâce à ses partenaires privilégiés."
          />

          <Question
            q="Comment puis-je souscrire à un compte B Partner en ligne ?"
            rep="La souscription au compte B Partner Premium se fait en ligne via https://app.b-partner.com/register. Pour les particuliers, l'enregistrement est entièrement en ligne. Les clients professionnels suivent le même processus, avec une validation supplémentaire par B Partner pour les contrôles réglementaires. L'offre Prestige est accessible sur invitation, avec un processus similaire en ligne."
          />

          <Question
            q="Quels services proposez-vous ?"
            rep="B Partner propose une gamme complète de services pour faciliter la gestion financière quotidienne, que ce soit à titre personnel ou professionnel. Pour les particuliers :  
B Partner Premium : Carte de débit VISA pour paiements globaux. Paiement en ligne pour factures en devises étrangères. Service de conciergerie pour assister dans les tâches quotidiennes (réservations, voyages, etc.).  
B Partner Prestige : Les mêmes services que l'offre Premium, avec en plus un assistant personnel Lifestyle Management pour des conseils personnalisés.  
Pour les professionnels :  
B Partner Premium Business et B Partner Prestige Business : Services similaires aux offres pour particuliers, avec des ajouts tels que la possibilité de souscrire à un terminal de paiement. Limites de paiement étendues pour une gestion financière aisée.  
Programme de fidélité B Partner Club : Accessible à tous les clients, ce programme récompense la fidélité par des points échangeables contre des cashback ou des expériences exclusives.  
B Partner se distingue par son approche zéro papier, fournissant tous les relevés et documents en ligne, téléchargeables par les clients."
          />
        </ComparatifAccordeonDesktop>

        {/* SECTION 1 : SECURITE ET FRAUDE */}
        <ComparatifAccordeonDesktop
          title="SECURITE ET FRAUDE"
          open={openSectionIndex === 1}
          onToggle={() => handleSectionToggle(1)}
        >
          <Question
            q="Quelle sont les options de sécurité pour mon compte en ligne ?"
            rep="Les accès en ligne au compte B Partner sont strictement personnels, et il incombe au client de protéger son identifiant et mot de passe. De plus, une authentification forte est requise pour valider certaines opérations, comme la création de bénéficiaires et les virements sortants, ainsi que pour se connecter à l’espace personnel sécurisé, en utilisant un code à usage unique envoyé sur le téléphone portable du client."
          />

          <Question
            q="Comment puis-je signaler un email ou un message suspect prétendant venir de votre banque ?"
            rep="À aucun moment B Partner ne vous demandera vos identifiants ou mots de passe de connexion. Nous invitons donc nos clients à être vigilants et ne pas divulguer ces informations qui sont strictement personnelles et confidentielles. En cas de suspicion, merci de contacter votre conseiller B Partner par téléphone ou via le formulaire mis à disposition dans le centre d’aide de votre Application."
          />

          <Question
            q="Quelles sont les mesures de sécurité en place pour les transactions en ligne ?"
            rep="Les transactions en ligne sont sécurisées par 3D Secure, nécessitant la validation par un code SMS. B Partner propose également des fonctionnalités de sécurité supplémentaires, comme la désactivation des transactions en ligne pour une carte, l'utilisation de cartes virtuelles pour les achats en ligne, et le blocage temporaire de la carte de paiement."
          />

          <Question
            q="Comment puis-je sécuriser mes informations personnelles ?"
            rep="B Partner garantit une gestion et un stockage sécurisé des données personnelles, détaillés dans leur Politique de Confidentialité disponible à l'adresse : https://b-partner.com/confidentialite.pdf. Le compte B Partner est strictement personnel, et il incombe aux clients de sécuriser leurs informations. Les identifiants et mots de passe doivent être stockés en toute sécurité et changés régulièrement. En cas de suspicion de compromission des données, il est recommandé de contacter B Partner par email à support@b-partner.com ou par téléphone au (+33) 01 82 83 06 80 (France) ou (+32) 02 808 44 80 (Belgique)."
          />

          <Question
            q="Quelles sont les conséquences d’un vol d’identité et comment puis-je m’en protéger ?"
            rep="Les fraudeurs développent des méthodes sophistiquées pour voler des identifiants et mots de passe, ce qui peut entraîner un accès illicite aux comptes B Partner. Pour contrer cela, B Partner utilise une authentification forte (SCA) basée sur l'envoi d'un code à usage unique (OTP) sur le téléphone portable du client. Ce code est requis pour valider des opérations sensibles, comme l'enregistrement d'un nouveau virement ou bénéficiaire. Bien que ces mesures puissent sembler contraignantes, elles visent à protéger les comptes et les fonds des clients."
          />

          <Question
            q="Quelles précautions dois-je prendre lorsque j’utilise des distributeurs automatiques de billets ?"
            rep="Les fraudeurs développent des méthodes sophistiquées pour voler des identifiants et des données confidentielles, y compris aux distributeurs automatiques de billets (DAB). Il est crucial de garder son code PIN personnel et de le protéger lors de sa saisie au DAB. En cas de doute sur la compromission de vos données, contactez rapidement B Partner par email à support@b-partner.com ou par téléphone au (+33) 01 82 83 06 80 (France) ou (+32) 02 808 44 80 (Belgique). L'application mobile B Partner permettra bientôt de bloquer temporairement l'usage de la carte de paiement, y compris pour les retraits aux DAB."
          />

          <Question
            q="Comment puis-je vérifier si le site web B Partner est sécurisé ?"
            rep="Le site web B Partner est sécurisé par l’usage d’un certificat et d’une signature digitale qui lui est propre. Cela se traduit par un accès via un URL sécurisé représenté par un URL en https. De plus, nous invitons nos clients à enregistrer l’URL du site B Partner https://app.b-partner.com/, et à ne jamais se connecter à un site qui vous semblerait suspect."
          />

          <Question
            q="Comment fonctionne l'authentification à deux facteurs (2FA) et pourquoi est-elle importante ?"
            rep="Les fraudeurs sont de plus en plus sophistiqués dans leurs méthodes de vol d'identité et de données confidentielles, y compris les identifiants et mots de passe. Pour contrer cela, B Partner utilise des mécanismes d'authentification forte comme l'OTP (One Time Password) envoyé sur le téléphone portable du client. Ces mesures sont cruciales pour sécuriser les opérations sensibles comme les virements ou l'ajout de bénéficiaires via l'Application web ou mobile de B Partner, malgré leur caractère contraignant au quotidien."
          />

          <Question
            q="Comment puis-je protéger mes informations personnelles contre le phishing ?"
            rep="B Partner assure une gestion sécurisée de vos données personnelles, détaillée dans notre Politique de Confidentialité disponible à l'adresse : https://b-partner.com/confidentialite.pdf.
Il est crucial que chaque client protège ses informations personnelles, notamment face aux tentatives de hameçonnage où des individus malveillants peuvent se faire passer pour des conseillers de B Partner via des emails frauduleux. Ne jamais cliquer sur les liens suspects pour éviter le risque de phishing et de virus. Les identifiants et mots de passe doivent être stockés de manière sécurisée et modifiés régulièrement. En cas de doute sur la sécurité de vos données, contactez rapidement B Partner par email à support@b-partner.com ou par téléphone (+33) 01 82 83 06 80 (France) ou (+32) 02 808 44 80 (Belgique)."
          />
        </ComparatifAccordeonDesktop>

        {/* SECTION 2 : SERVICES ET COMPTES */}
        <ComparatifAccordeonDesktop
          title="SERVICES ET COMPTES"
          open={openSectionIndex === 2}
          onToggle={() => handleSectionToggle(2)}
        >
          <Question
            q="Comment signaler une carte de paiement perdue ou volée ?"
            rep="Pour signaler une carte de paiement B Partner comme étant perdue ou volée, merci de prendre contact avec nos équipes au numéro suivant depuis la France (+33) 01 82 83 06 80, et sur le numéro suivant depuis la Belgique (+32) 02 808 44 80. En dehors de ces heures, et à tout moment, vous pouvez mettre votre carte en opposition depuis votre ordinateur ou téléphone portable en vous connectant à votre espace personnel B Partner."
          />

          <Question
            q="Quels sont les frais de transaction internationale ?"
            rep="Les frais prélevés par B Partner pour les transactions internationales varient selon le type de compte (particulier ou professionnel) et l'offre souscrite (Premium ou Prestige). Ils dépendent également de la devise locale et du pays de la transaction. Les détails complets de ces frais sont disponibles dans nos conditions tarifaires consultables à l'adresse : https://b-partner.com/conditionTarifParticuliers.pdf"
          />

          <Question
            q="Comment puis-je changer mon code PIN ?"
            rep="Pour changer le code PIN (Personnal Identification Number) de vos carte de paiement, il faudra vous rendre dans un distributeur automatique de billets (DAB), et choisir sur ce dernier l’option de changements de code PINs."
          />

          <Question
            q="Comment puis-je consulter l’historique de mes transactions ?"
            rep="L’historique des transactions entrantes et sortantes, qu’ils soient virements, prélèvements, opérations en euro ou en devises étrangères, transactions cartes ou autres, peuvent être visualisées en ligne sur l’Application Web et Mobile B Partner."
          />

          <Question
            q="Comment puis-je effectuer un transfert de fonds international ?"
            rep="B Partner propose une solution de paiement dans plus de 50 devises pour ses clients particuliers et professionnels, sous réserve de justificatif. Cette solution permet des transferts internationaux vers près de cent pays. Le client doit d'abord enregistrer le bénéficiaire du transfert, spécifiant une devise différente de l'Euro. B Partner présente un taux de change au client pour évaluer le montant final transféré. Une fois validé, B Partner transfère automatiquement le montant en Euro, y compris les frais, à son partenaire pour effectuer le paiement dans la devise du bénéficiaire. Les délais dépendent principalement de la devise de transfert. Pour toute question, les clients peuvent contacter B Partner par email à support@b-partner.com ou par téléphone au (+33) 01 82 83 06 80 (France) ou (+32) 02 808 44 80 (Belgique)."
          />

          <Question
            q="Quels sont les horaires d'ouverture de vos agences ?"
            rep="B Partner, une fintech spécialisée dans la gestion innovante des finances, est basée à Paris. Bien que nous n'ayons pas d'agences traditionnelles, nous sommes disponibles pour rencontrer nos clients sur rendez-vous. Pour toute question ou assistance, nos équipes sont joignables par email à support@b-partner.com ou par téléphone au (+33) 01 82 83 06 80 en France et au (+32) 02 808 44 80 en Belgique, du lundi au vendredi de 9h00 à 18h00. Les clients Premium ont accès au service Conciergerie B Partner Premium, et les clients Prestige au service Assistant Personnel Lifestyle Management, disponibles par téléphone en continu."
          />
          <Question q='Quels sont les services de base ?' rep={<p>Toute souscription et activation d’un compte B Partner ouvre automatiquement un compte accompagné d’une carte de débit à autorisation immédiate. Le client peut gérer ses paiements SEPA ou en devises directement depuis l’application Web ou mobile B Partner. Il peut également consulter ses opérations et configurer les paramètres de sa carte de paiement. Enfin, le Programme de Fidélité B Partner lui permet de cumuler des points convertibles en récompenses.<br/>Pour plus d’informations sur ces services, veuillez consulter nos CGU à <a className='font-bold underline' href='/pdf?doc=cgu.pdf' target='_blank'>ce lien.</a></p>}/>

          <Question q='Quels sont les services complémentaires optionnels ?' rep={<>
            <p>Les services complémentaires optionnels incluent :</p>
            <ul className='list-disc'>
              <li>Conciergerie</li>
              <li>Assistant personnel*</li>
              <li>E-réputation*</li>
              <li>Conseiller financier*</li> 
            </ul>
            <p>Pour plus d’informations sur ces services, veuillez consulter nos CGU Services Complémentaires à <a className='font-bold underline' href='/pdf?doc=b-partner_cgu-services-complementaires.pdf' target='_blank'>ce lien.</a></p>
            <p className='text-[0.7rem] italic'>*Ces services sont exclusivement réservés aux clients B Partner Prestige et B Partner Prestige Business.</p></>}/>

        </ComparatifAccordeonDesktop>

        {/* SECTION 3 : CARTES ET PAIEMENTS */}
        <ComparatifAccordeonDesktop
          title="CARTES ET PAIEMENTS"
          open={openSectionIndex === 3}
          onToggle={() => handleSectionToggle(3)}
        >
          <Question
            q="Comment puis-je augmenter la limite de ma carte de paiement ?"
            rep="Les plafonds de paiements et de retraits de nos cartes B Partner sont très élevées, et ce, dès l’entrée en relation. L’objectif est de pouvoir répondre au mieux aux exigences de nos clients s’agissant de l’accès aux fonds disponibles sur leur compte B Partner. Si ces derniers devaient néanmoins se révéler insuffisants, ils pourront être modifiés en ligne via l’Application Web ou Mobile."
          />

          <Question
            q="Quels sont les avantages des cartes de débit VISA émis par B Partner ?"
            rep="B Partner propose une carte de débit VISA pour chaque compte, qu'il soit Premium ou Prestige, particulier ou professionnel. Ces cartes permettent de réaliser des achats et des retraits partout dans le monde et en ligne. Les transactions en ligne sont sécurisées par la fonctionnalité 3D Secure. Les clients peuvent également utiliser des cartes virtuelles pour des paiements à distance, gérées via l'application web et mobile B Partner, afin de protéger les données de leur carte physique."
          />

          <Question
            q="Comment puis-je bloquer ou débloquer ma carte de pâiement ?"
            rep="La carte de débit B Partner peut être temporairement verrouillée ou déverrouillée en ligne, offrant ainsi une protection contre les paiements ou retraits non autorisés. Cependant, cette fonctionnalité ne remplace pas la mise en opposition, qui reste indispensable en cas de perte, de vol ou d'utilisation abusive de la carte."
          />

          <Question
            q="Comment puis-je demander une nouvelle carte de paiement ?"
            rep="Lors de l'ouverture d'un compte B Partner, une carte de débit VISA est automatiquement commandée, sous réserve du paiement initial des frais de compte, comme indiqué dans les Conditions Générales d’Utilisation et les Conditions Tarifaires. En cas de vol, perte, ou détérioration de la carte, les clients doivent contacter un conseiller B Partner pour en demander une nouvelle. Le service client est joignable du lundi au vendredi, de 9h à 18h, par téléphone au 01 82 83 06 80 (France) ou 02 808 44 80 (Belgique)."
          />

          <Question
            q="Comment puis-je demander une nouvelle carte de paiement ?"
            rep=""
          />
        </ComparatifAccordeonDesktop>

        {/* SECTION 4 : ASSISTANCE ET SUPPORT */}
        <ComparatifAccordeonDesktop
          title="ASSISTANCE ET SUPPORT"
          open={openSectionIndex === 4}
          onToggle={() => handleSectionToggle(4)}
        >
          <Question
            q="Comment puis-je faire une réclamation ?"
            rep={`Toutes les réclamations concernant les produits et services de B Partner doivent être adressées à l’adresse électronique reclamations@b-partner.com. Veuillez joindre le formulaire de réclamation complété avec précision. En cas d'insatisfaction persistante, vous avez la possibilité de saisir le service des litiges de la Commission européenne (\"ODR\") en ligne. Cette plateforme tente de résoudre de manière indépendante et impartiale les différends entre les parties pour parvenir à une solution amiable. Vous pouvez accéder à la plateforme ODR à l'adresse suivante : https://ec.europa.eu/consumers/odr/main/?event=main.adr.show`}
          />

          <Question
            q="Comment contacter le service client ?"
            rep="Le service client B Partner est disponible du lundi au vendredi par téléphone, de 9h à 18h. Vous pouvez nous contacter depuis la France au 01 82 83 06 80 et depuis la Belgique au 02 808 44 80. Pour des questions générales, vous pouvez nous écrire à contact@b-partner.com. Pour toute assistance technique concernant votre compte, l'application ou les produits B Partner, veuillez nous contacter à support@b-partner.com."
          />

          <Question
            q="Comment changer mon adresse ou mes informations personnelles ?"
            rep="Vous pouvez modifier votre adresse fiscale ou de correspondance en ligne via le menu Paramètres de l'Application B Partner. Vous devrez fournir la nouvelle adresse ainsi qu'un justificatif de domicile datant de moins de 2 mois. Votre demande sera examinée par nos équipes Back-Office et traitée dans les 48 heures suivant sa réception."
          />

          <Question
            q="Quels sont vos horaires d’ouvertures ?"
            rep="Nos conseillers sont disponibles du lundi au vendredi de 9h à 18h00. Pour nos clients Premium, nos concierges sont joignables du lundi au samedi de 9h à 20h en français et en anglais. Pour nos clients Prestige, nos assistants personnels Lifestyle Management sont disponibles 24h/24 et 7j/7 en français·et·en·anglais."
          />

          <Question
            q="Comment évaluer la qualité de l'assistance fournie par votre service client ?"
            rep="B Partner place la qualité de service et la satisfaction client au cœur de ses préoccupations. Ainsi, des enquêtes de satisfaction sont régulièrement proposées en ligne ou via l'application B Partner. Ces questionnaires permettent aux clients d'évaluer les services offerts et de suggérer des améliorations pour l'accompagnement, les services, et les produits de B Partner."
          />
        </ComparatifAccordeonDesktop>

        {/* SECTION 5 : TARIFS ET CONDITIONS */}
        <ComparatifAccordeonDesktop
          title="TARIFS ET CONDITIONS"
          open={openSectionIndex === 5}
          onToggle={() => handleSectionToggle(5)}
        >
          <Question
            q="Quels sont les frais mensuels pour la gestion des comptes courants ?"
            rep="Le compte de monnaie électronique B Partner est conçu pour une clientèle exigeante, offrant des services de haute valeur ajoutée et un accompagnement personnalisé. Pour soutenir cette qualité de service, B Partner propose un abonnement mensuel. Les clients particuliers peuvent opter pour un paiement mensuel ou annuel, avec un engagement de 3 mois. Les clients professionnels, quant à eux, doivent s'acquitter d'un abonnement annuel avec un engagement de 6 mois. Les détails des tarifs et frais sont disponibles dans les Conditions Tarifaires en ligne."
          />

          <Question
            q="Y a-t-il des frais pour réaliser des virements ?"
            rep="Le compte de monnaie électronique B Partner est conçu pour une clientèle exigeante, cherchant qualité et personnalisation dans la gestion de leurs finances. B Partner offre des services de haute valeur ajoutée avec une disponibilité constante pour l'accompagnement des clients. Les virements sortants sont gratuits, seuls les virements instantanés sont facturés pour les clients particuliers. Les détails des tarifs et frais peuvent être consultés dans les Conditions Tarifaires en ligne."
          />

          <Question
            q="Quels sont les frais pour les retraits à l'étranger avec ma carte de paiement ?"
            rep="Le compte de monnaie électronique B Partner est conçu pour une clientèle exigeante, cherchant qualité et personnalisation dans la gestion de leurs finances. B Partner offre des services à haute valeur ajoutée avec un support client disponible pour un accompagnement quotidien. Pour les retraits d'espèces à l'étranger, les frais varient selon le pays et la banque du distributeur automatique. Ces frais diffèrent également en fonction de la devise, qu'il s'agisse d'euros ou de devises étrangères. Les détails des tarifs et frais sont disponibles dans les Conditions Tarifaires en ligne."
          />

          <Question
            q="Quels sont les frais associés à la clôture d'un compte de monnaie électronique ?"
            rep="Le compte de monnaie électronique B Partner est conçu pour une clientèle exigeante, offrant qualité et personnalisation dans la gestion financière. B Partner assure un accompagnement quotidien avec des services de haute valeur ajoutée. Pour les frais de clôture, ils sont inexistants si la demande émane du client, sous réserve d'un engagement minimal de 3 mois pour les comptes particuliers et de 6 mois pour les comptes professionnels. En cas de clôture initiée par B Partner, les frais peuvent varier et seront communiqués directement au client concerné. Les détails des tarifs et frais sont disponibles en ligne dans les Conditions Tarifaires."
          />

          <Question
            q="Y a-t-il des frais pour les services en ligne?"
            rep="Le compte de monnaie électronique B Partner est conçu pour une clientèle exigeante, recherchant qualité et personnalisation dans la gestion financière. B Partner offre des services à forte valeur ajoutée et assure un accompagnement quotidien par ses équipes. L'accès à l'application et aux conseillers est gratuit. Cependant, les clients doivent disposer de leur propre accès internet et d'un terminal, tel qu'un ordinateur ou un téléphone portable, pour utiliser l'application."
          />

          <Question
            q="Quels sont les frais pour l'utilisation de votre application mobile ?"
            rep="Le compte de monnaie électronique B Partner est conçu pour une clientèle exigeante, recherchant qualité et personnalisation dans la gestion de leurs finances. En plus des services à forte valeur ajoutée, les équipes B Partner sont disponibles pour un accompagnement quotidien. Les clients peuvent accéder gratuitement à l'application en ligne et aux conseillers, mais doivent disposer de leur propre accès internet et d'un terminal, comme un ordinateur ou un téléphone portable."
          />

          <Question
            q="Quels sont les frais pour l'émission de chèques ?"
            rep="Le compte de monnaie électronique B Partner, est une solution développée afin de répondre à une clientèle exigeante, en quête de qualité et de personnalisation dans la gestion de leur finance. B Partner ne permet pas l’émission de chèque, ou le dépôt de chèque sur le compte du client."
          />

          <Question
            q="Quels sont les frais pour les découverts de monnaies électriques ?"
            rep="Le compte B Partner est un compte de monnaie électronique, auquel est associé une carte de paiement à débit immédiat. Il n’est donc pas possible pour le client d’être en situation de découvert sur son compte B Partner."
          />

          <Question
            q="Quels sont les frais pour l'envoi de relevés papier ?"
            rep="B Partner, axé sur l'avenir, adopte une approche zéro papier pour préserver l'environnement. Les relevés d'opérations ne sont pas fournis en format papier, mais sont disponibles en ligne pour téléchargement et impression par le client."
          />

    <Question q='Comment puis-je obtenir une copie de votre grille tarifaire complète ?' rep="B Partner met à disposition de ses clients ou prospects ses Conditions Tarifaires complètes. Elles peuvent être consulté en ligne à l’adresse URL : www.b-partner.com"/>
    <Question q='Comment activer les services complémentaires optionnels ?' rep={<p>
      Les services complémentaires optionnels sont activés automatiquement, sans action requise de la part du client B Partner. Ils sont facturés sur une base mensuelle avec un engagement minimum pour le mois entamé.
      <br/>
      Le client peut résilier ces services à tout moment en contactant les équipes B Partner par email à <a href='mailto:support@b-partner.com' className='underline'>support@b-partner.com</a>.
    </p>}/>
    </ComparatifAccordeonDesktop>

        {/* SECTION 6 : SERVICES POUR LES ENTREPRISES */}
        <ComparatifAccordeonDesktop
          title="SERVICES POUR LES ENTREPRISES"
          open={openSectionIndex === 6}
          onToggle={() => handleSectionToggle(6)}
        >
          {/* <Question q='Quels services proposez-vous aux entreprises ?' rep="..." /> */}
          <Question
            q="Quels services de trésorerie proposez-vous pour les entreprises ?"
            rep="Le compte B Partner est un compte de monnaie électronique, auquel est associé une carte de paiement. A ce jour B Partner n’est pas en capacité de fournir de solution de trésorerie, ou de découvert aux clients professionnels."
          />

          <Question
            q="Proposez-vous des solutions de paiement pour les entreprises ?"
            rep="B Partner propose aux clients professionnels deux gammes de services : B Partner Premium Business et B Partner Prestige Business. Indépendamment de la gamme choisie, les clients ont accès à une variété de solutions de paiement et de gestion des flux financiers :
- Carte de débit immédiat VISA : Permet de réaliser des achats et paiements dans le monde entier sur les terminaux compatibles VISA.
- Virements SEPA : Facilite les paiements en euros vers les 28 pays de l'Union Européenne ainsi que la Suisse, la Norvège, l'Islande, l'Andorre, le Vatican, le Liechtenstein, Monaco et Saint-Marin.
- Virements SEPA instantanés : Permet de transférer des fonds jusqu'à 100 000 euros en seulement 10 secondes vers les mêmes pays que le SEPA standard.
- Paiements en devises étrangères : Offre la possibilité de régler des factures dans plus de 50 devises différentes.
Ces services sont accessibles en ligne via l'application web de B Partner, avec une version mobile prévue prochainement."
          />

          <Question
            q="Quels services de change et de gestion des devises proposez-vous ?"
            rep="B Partner permet à ses clients particuliers et professionnels d'effectuer des paiements en devises étrangères, avec certaines limitations spécifiées dans les Conditions Générales d’Utilisation disponibles en ligne. Ces opérations sont réalisées en partenariat avec OFX, une société basée en Irlande, reconnue mondialement et active depuis plus de 25 ans. OFX facilite l'envoi de devises vers 170 pays, couvrant une cinquantaine de devises différentes. Pour utiliser ce service, disponible en ligne pour tous les clients B Partner, des justificatifs peuvent être requis concernant la destination et l'usage des fonds transférés."
          />

          <Question
            q="Offrez-vous des solutions de commerce international ?"
            rep="B Partner propose à ses clients, tant particuliers que professionnels, la possibilité d'effectuer des paiements en devises étrangères, sous réserve des limitations mentionnées dans les Conditions Générales d’Utilisation disponibles en ligne. Ces transactions sont réalisées en partenariat avec OFX, une société irlandaise renommée, active depuis plus de 25 ans, qui permet l'envoi de devises vers 170 pays dans environ cinquante devises. Ce service est accessible en ligne pour tous les clients B Partner, sous réserve de fournir des justificatifs sur la destination et l'utilisation des fonds. B Partner et OFX se réservent le droit de refuser un ordre de paiement en cas de doute ou de risque de non-conformité avec les réglementations en vigueur. Le taux de change applicable est affiché lors de la saisie du paiement en devise, mais il n'est garanti qu'au moment où le client confirme son ordre de paiement, et est soumis à une durée de validité limitée."
          />

          <Question
            q="Quels sont les frais associés à vos services pour les entreprises ?"
            rep="B Partner met à disposition de ses clients ou prospects ses Conditions Tarifaires complètes. Elles peuvent être consulté en ligne à l’adresse URL : www.b-partner.com"
          />

          <Question
            q="Proposez-vous des services de conseil en investissement pour les entreprises ?"
            rep="B Partner propose un compte de monnaie électronique conçu pour une clientèle exigeante, recherchant qualité et personnalisation dans la gestion de leurs finances. Outre une gamme complète de services, B Partner collabore avec des experts en gestion financière et patrimoniale pour répondre aux besoins complémentaires de ses clients, en particulier les dirigeants d'entreprises. Ce service est offert en partenariat avec Expertalys, une société française reconnue pour son expertise en gestion patrimoniale et diversification financière. Ce partenariat vise à fournir des solutions assurantielles complètes, consolidant ainsi B Partner comme un partenaire privilégié au quotidien pour ses clients. Pour les clients B Partner Prestige, un conseiller en gestion patrimoniale est disponible pour offrir des conseils sur divers aspects financiers, tels que la fiscalité, la gestion de la dette, le patrimoine professionnel, les investissements immobiliers, l'assurance vie, les placements financiers, la prévoyance, la transmission de patrimoine, et la préparation à la retraite. Les clients peuvent accéder à ce service via le numéro de téléphone disponible sur l'application Web et Mobile B Partner. Pour des services étendus au-delà du bilan patrimonial initial, les clients devront conclure un accord directement avec Expertalys."
          />

          <Question
            q="Comment puis-je accéder à mes comptes d'entreprise en ligne ?"
            rep="B Partner permet aux représentants légaux d'entreprises de souscrire aux offres B Partner Premium Business ou Prestige Business au nom de leur entreprise. Une fois la souscription validée, ces représentants ont automatiquement accès au compte de l'entreprise sans démarches supplémentaires. Si le représentant légal n'est ni actionnaire ni bénéficiaire effectif, il doit prouver son mandat de gestion auprès de B Partner. L'accès au compte est strictement personnel pour le représentant légal et ne peut être partagé avec d'autres employés. En cas de changement de représentant légal, une personne autorisée doit en informer B Partner pour mettre à jour les informations. Il est crucial de respecter cette procédure pour éviter tout préjudice à la gestion financière de l'entreprise."
          />

          <Question
            q="Comment puis-je bénéficier des prêts pour PME (Petites et Moyennes Entreprises) ?"
            rep=""
          />

          <Question
            q="Quels sont les documents nécessaires pour ouvrir un compte entreprise ?"
            rep=""
          />
        </ComparatifAccordeonDesktop>

        {/* SECTION 7 : PRODUITS ET SERVICES FINANCIERS */}
        <ComparatifAccordeonDesktop
          title="PRODUITS ET SERVICES FINANCIERS"
          open={openSectionIndex === 7}
          onToggle={() => handleSectionToggle(7)}
        >
          <Question
            q="Proposez-vous des produits d'investissement ?"
            rep="B Partner ne propose pas de produits d'investissement et ne fait pas la promotion de tels produits. Pour répondre aux besoins complémentaires de ses clients, B Partner collabore avec des experts en gestion financière et patrimoniale, tels que la société Expertalys, établie en France. Ce partenariat vise à offrir une gamme complète de solutions assurantielles aux clients, en particulier ceux disposant de comptes B Partner. Les clients B Partner Prestige peuvent accéder à ces services en contactant directement leur conseiller en gestion patrimoniale via l'Application Web et Mobile B Partner. Les conseillers d'Expertalys offrent des services dans plusieurs domaines, dont la fiscalité, la gestion de la dette, la gestion du patrimoine professionnel, les investissements immobiliers, l'assurance-vie, les placements financiers, la prévoyance, la transmission de patrimoine, et la planification de la retraite. Si un client souhaite bénéficier de services supplémentaires au-delà du bilan patrimonial initial, il devra contractualiser ces services directement avec Expertalys. Plus d'informations sur Expertalys sont disponibles sur leur site : www.expertalys.fr."
          />

          <Question
            q="Comment puis-je commencer à investir chez B Partner?"
            rep="Le compte de monnaie électronique B Partner est conçu pour une clientèle recherchant qualité et personnalisation dans la gestion financière. En plus de services de haute valeur ajoutée, B Partner propose un accompagnement personnalisé pour répondre aux besoins complémentaires de ses clients. Pour cela, B Partner a noué un partenariat avec Expertalys, une société française spécialisée en gestion patrimoniale et diversification financière. Ce partenariat permet d'offrir une gamme complète de solutions assurantielles, renforçant ainsi le rôle de B Partner comme partenaire privilégié de ses clients. Les clients B Partner Prestige peuvent accéder à ces services en contactant directement leur conseiller en gestion patrimoniale via l'application B Partner. Les services incluent des conseils en fiscalité, gestion de la dette, optimisation du patrimoine professionnel, investissements immobiliers, assurance vie, placements financiers, prévoyance, transmission de patrimoine, et planification de la retraite. Les clients souhaitant des services supplémentaires devront contractualiser directement avec Expertalys pour la suite des prestations. Plus d'informations sont disponibles sur le site www.expertalys.fr."
          />

          <Question
            q="Quels services de conseil en investissement proposez-vous ?"
            rep="Le compte de monnaie électronique B Partner est conçu pour des clients exigeants cherchant qualité et personnalisation dans la gestion de leurs finances. Outre des services de haute valeur ajoutée, B Partner offre un accompagnement personnalisé en partenariat avec Expertalys, une société française reconnue pour sa gestion patrimoniale et diversification financière. Ce partenariat vise à fournir une gamme complète de solutions assurantielles, renforçant B Partner comme partenaire privilégié. Les clients B Partner Prestige peuvent accéder à ces services en contactant directement leur conseiller en gestion patrimoniale via l'application B Partner. Les domaines d'assistance incluent :  
· Conseils en fiscalité : Bilan, revue, et optimisation  
· Gestion de la dette : Restructuration et optimisation des outils et dettes  
· Patrimoine professionnel : Optimisation, transmission, successions, placements, immobilier et SCI  
· Investissements immobiliers : Conseil pour investissements et acquisitions  
· Assurance vie : Souscription, transfert, rédaction des clauses bénéficiaires  
· Placements financiers : Analyse de portefeuille, valorisation via PEA, Comptes-titres, etc.  
· Prévoyance : Bilan des couvertures, optimisation des garanties et contrats, professions libérales  
· Transmission de patrimoine : Donation, don d’usufruit, SCI, vente en viager  
· Retraite : Actualisation des droits, anticipation des besoins, capitalisation  
Les clients désirant des services additionnels devront établir un contrat directement avec Expertalys. Plus d'informations sont disponibles sur le site d'Expertalys."
          />
        </ComparatifAccordeonDesktop>

        {/* SECTION 8 : AUTRES SERVICES */}
        <ComparatifAccordeonDesktop
          title="AUTRES SERVICES"
          open={openSectionIndex === 8}
          onToggle={() => handleSectionToggle(8)}
        >
          <Question
            q="Proposez-vous des services de conseils en d’investissement ?"
            rep="B Partner ne fournit pas directement ces services en raison des limitations de ses licences accordées par la Banque Nationale de Belgique. Cependant, B Partner a établi un partenariat non exclusif avec des experts conseillers en gestion patrimoniale, comme Expertalys. Ce partenariat permet à Expertalys d'accompagner les clients de B Partner dans des domaines tels que la diversification, la gestion de la dette, l'épargne, et d'autres services connexes."
          />

          <Question
            q="Comment puis-je accéder à mon compte de monnaie électronique depuis l’étranger ?"
            rep="B Partner propose à tous ses clients un accès illimité à l'Application Web et Mobile B Partner, accessible partout dans le monde, sous réserve d'une connexion internet de qualité suffisante."
          />

          <Question
            q="Comment puis-je effectuer un transfert de fonds internationale ?"
            rep="B Partner offre à ses clients particuliers et professionnels la possibilité d'effectuer des paiements en devises étrangères, en respectant les limitations précisées dans les Conditions Générales d’Utilisation disponibles en ligne. Ces opérations sont réalisées en partenariat avec OFX, une entreprise irlandaise de renom mondial, active depuis plus de 25 ans, qui facilite l’envoi de devises vers 170 pays dans une cinquantaine de devises. Plus d'informations sur OFX sont disponibles sur leur site. Le service est accessible en ligne pour tous les clients B Partner, sous réserve de fournir les justificatifs nécessaires concernant la destination et l’usage des fonds transférés. B Partner et OFX se réservent le droit de refuser des transactions en cas de doute ou de risque potentiel concernant les réglementations en vigueur. Le taux de change applicable sera affiché en ligne lors de la saisie du paiement en devise. Ce taux est valable pour une durée limitée et ne peut être garanti qu'au moment où le client confirme son ordre de paiement."
          />

          <Question
            q="Proposez-vous des services de conseil en investissement ?"
            rep=""
          />

          <Question
            q="Comment puis-je accéder aux rapports financiers de la banque ?"
            rep=""
          />

          <Question
            q="Quels sont les avantages des services B Partner pour les étudiants ?"
            rep=""
          />

          <Question
            q="Proposez-vous des comptes islamiques conformes à la charia ?"
            rep=""
          />
        </ComparatifAccordeonDesktop>
      </div>
    </div>
  );
}
